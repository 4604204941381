// Color Palette
$shark: #282c34;
$purple-heart: #731ACC;
$royal-purple: #6E4299;
$blue-ribbon: #0711FF;
$my-sin: #FFBC1D;
$yellow-sin: #F7AF02;
$serenade: #FFF0E2;

.portfolio {
  background-color: $shark;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  text-align: center;

  h1 {
    margin: 0 0 20px;
    font-size: 45px;
  }

  p {
    margin: 0 10px 20px;
  }
}
