#modal-overlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

#modal {
  z-index: 3;
  position: absolute;
  left: 50%;
  top: 18%;
  transform: translate(-50%);

  .hex-interior {
    text-align: center;
    max-height: 400px;

    svg {
      width: auto;
      margin: -50px 0 10px;
      font-size: 50px;

      @media (min-width: 481px) {
        margin: -90px 0 10px;
      }
    }

    .modal-body {
      overflow: auto;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      flex-flow: column;
    }

    .btn-close {
      float: right;
      position: absolute;
      top: -90px;
      left: 305px;
      font-size: 30px;
      border: none;
      display: inline-flex;

      @media (min-width: 481px) {
        left: 405px;
      }
    }

    .screen-reader-offscreen {
      position:absolute;
      left:-999px;
      width:1px;
      height:1px;
      top:auto;
    }
  }
}
