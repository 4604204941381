@import "../App.scss";

$xxsWidth: 45px;
$xsWidth: 55px;
$smWidth: 100px;
$smHeight: $smWidth/1.73;
$mdWidth: 140px;
$lgWidth: 175px;
$xlWidth: 260px;
$xxlWidth: 350px;
$xxxlWidth: 450px;

@mixin hex($width, $color) {
  $height: $width/1.73;
  transition: width 1000ms, height 1000ms, margin 1000ms, background-color 1000ms, color 1000ms;
  position: relative;
  width: $width;
  height: $height;
  background: $color;
  margin: $height/3 auto $height/3 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
  }

  &::before {
    bottom: 100%;
    border-bottom: $height/2 solid $color;
    transition: bottom 1000ms, border 1000ms;
  }

  &::after {
    top: 100%;
    border-top: $height/2 solid $color;
    transition: top 1000ms, border 1000ms;
  }

  &:focus {
    outline: none;
  }

  .hex-interior {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      transition: font-size 1000ms, color 1000ms;
      color: white;
      margin-right: 0;
      font-size: $width/2;
    }

    .modal-toggle {
      border: none;
      padding: 4px 8px;
      display: inline-block;
      font-size: 16px;
      margin-top: 10px;
      border-radius: 5%;
      
      color: $my-sin;
      background-color: $shark;
      animation: createBox .5s, pulseOut 3s infinite ease-in-out ;
    }

    @keyframes createBox {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
    
    @keyframes pulseOut {
      0% { 
        box-shadow: 0 0 0 5px rgba(255, 188, 29, 0);
      }
      50% {
        border-radius: 1%;
        box-shadow: 0 0 0 5px rgba(40, 44, 52, .57);
      }
      100% {
        box-shadow: 0 0 0 5px rgba(255, 188, 29, 1);
      }
    }

  }
}

.hex-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .hex-row {
    display:flex;
    flex-direction: row;
    align-items: center;

    > :first-child {
      margin-right: 10px;
    }

    &:first-child {
      align-items: flex-end;
      margin-top: 10px;

      .hex-sm {
        margin-bottom: 27px;
      }
    }

    &.inverted {
      &:first-child,
      &:last-child {
        .hex-md {
          margin-left: 33px;
        }
      }
    }

    &.inverted {
      &:nth-child(2) {
        .hex-md {
          margin-left: 33px;
        }

        .hex-lg {
          margin-left: 17px;
        }
      }
    }

    &:not(.inverted) {
      &:nth-child(2) {
        > .hex-lg {
          margin-left: -10px;
        }
      }
    }

    &:last-child {
      align-items: flex-start;

      .hex-sm {
        margin: $smHeight/2.2 10px $smHeight/3 0;
      }
    }
  }
}

.hex-xxs {
  @include hex($xxsWidth, $purple-heart);

  color: $serenade;
}

.hex-xs {
  @include hex($xsWidth, $my-sin)
}

.hex-sm {
  @include hex($smWidth, $royal-purple)
}

.hex-md {
  @include hex($mdWidth, $blue-ribbon)
}

.hex-lg {
  @include hex($lgWidth, $my-sin);

  .hex-interior {
    svg {
      color: $shark;
    }
  }
}

.hex-xl {
  @include hex($xlWidth, $yellow-sin)
}

.hex-modal {
  @include hex($xxlWidth, $yellow-sin);
  height: auto;
  min-height: $xxlWidth/1.73;
  max-height: 60%;
  color: $shark;

  @media (min-width: 481px) {
    @include hex($xxxlWidth, $yellow-sin);
    height: auto;
    min-height: $xxxlWidth/1.73;
  }

  .hex-interior {
    svg {
      color: $shark;
    }
  }
}

